<template>
  <section>
    <div class="row mb-3">
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <div>
          <h3 class="bis d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="shopping-cart"></icon>
            </div>
            <div>
              <div class="d-flex">
                <div>Liste des {{ orders.length }} commandes de</div>
                <m-form-select
                  :name="$Utils.randomstring('items_years')"
                  :items="$store.state.items_years"
                  style="width:110px"
                  class="ms-2 input-year"
                  :clearable="false"
                  v-model="year"
                ></m-form-select>
                <!-- <m-form-date
                  class="ms-2"
                  style="width:100px"
                  :name="$Utils.randomstring('annee')"
                  formatInput="YYYY"
                  v-model="year"
                  :year="true"
                  classInput="year-concours"
                ></m-form-date> -->
              </div>
              <div class="fs-6 fw-light">Liste des commandes de macarons</div>
            </div>
          </h3>
        </div>
        <div>
          <button class="btn btn-primary" type="button" @click="newOrders">
            Nouveau
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <!-- <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de commandes</div>
            <div class="stat-subtext">en {{ $store.state.year }}</div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.wines.color">
            {{ $store.state.stats.wines.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.wines.nb }}</div>
      </div> -->
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Chiffre d'affaires</div>
            <div class="stat-subtext">
              des commandes {{ $store.state.year }}
            </div>
          </div>
          <div class="stat-percent" :class="$store.state.stats.caOrders.color">
            {{ $store.state.stats.caOrders.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">
          {{ ($store.state.stats.caOrders.nb / 100) | formatCa }}
        </div>
      </div>
      <div class=" w-100 me-4 "></div>
      <div class=" w-100 me-4 "></div>
      <div class=" w-100 me-4 "></div>
    </div>
    <div style="color:#d72f3c;">
      ATTENTION, UNE COMMANDE "EN CRÉATION" SIGNIFIE QUE LE CLIENT A CRÉÉ UNE
      COMMANDE MAIS N'EST PAS ALLÉ JUSQU'AU BOUT DE LA PROCÉDURE (NON VALIDÉ).
      HABITUELLEMENT VOUS POURREZ L'EFFACER AU BOUT DE QUELQUES JOURS.
    </div>
    <div class="d-flex align-items-end justify-content-between mb-2">
      <div class="d-flex align-items-end">
        <m-form-text
          label="Recherche textuelle"
          v-model="filterText"
          :name="$Utils.randomstring('search')"
          autocomplete
          @input="loadDelayOrders()"
        ></m-form-text>
        <m-form-checkbox
          class="ms-2"
          label="En création"
          :name="$Utils.randomstring('filterCreating')"
          v-model="filterCreating"
          @input="loadDelayOrders()"
        ></m-form-checkbox>
        <m-form-checkbox
          class="ms-2"
          label="Validée"
          :name="$Utils.randomstring('filterValidated')"
          v-model="filterValidated"
          @input="loadDelayOrders()"
        ></m-form-checkbox>
        <m-form-checkbox
          class="ms-2"
          label="Réceptionnées"
          :name="$Utils.randomstring('filterReceived')"
          v-model="filterReceived"
          @input="loadDelayOrders()"
        ></m-form-checkbox>
        <m-form-checkbox
          class="ms-2"
          label="À expédier"
          :name="$Utils.randomstring('filterReceived')"
          v-model="filterPayed"
          @input="loadDelayOrders()"
        ></m-form-checkbox>
        <m-form-checkbox
          class="ms-2"
          label="Envoyées"
          :name="$Utils.randomstring('filterSended')"
          v-model="filterSended"
          @input="loadDelayOrders()"
        ></m-form-checkbox>
      </div>
    </div>
    <div class="frame">
      <m-list-simple
        tableClass="table-hover table-striped"
        ref="orderslist"
        :dynamic="true"
        :items="orders"
        item-value="or_id"
        :item-height="30"
        :limit="orders_limit"
        :skip="orders_skip"
        :total="orders_total"
        :cursorOnRows="true"
        @itemclick="orderDetails"
      >
        <template v-slot:ths="{}">
          <th @click="sortTable(['or_society_fac'])">
            <span class="pointer">Société</span>
          </th>
          <th @click="sortTable(['or_name_liv'])">
            <span class="pointer">Contact</span>
          </th>
          <th @click="sortTable(['or_or'])">
            <span class="pointer">or</span>
          </th>
          <th @click="sortTable(['or_argent'])">
            <span class="pointer">Argent</span>
          </th>
          <th @click="sortTable(['or_excellence'])">
            <span class="pointer">Excellence</span>
          </th>
          <th @click="sortTable(['or_date'])">
            <span class="pointer">Date de la commande</span>
          </th>
          <!-- <th @click="sortTable(['in_num'])">
            <span class="pointer">Num facture</span>
          </th> -->
          <th @click="sortTable(['or_price_ht'])" class="text-end">
            <span class="pointer">Prix HT</span>
          </th>
          <th @click="sortTable(['or_payed'])">
            <span class="pointer">Payé</span>
          </th>
          <th @click="sortTable(['or_state'])">
            <span class="pointer">Envoyé</span>
          </th>
          <th style="width:50px;"></th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.or_society_fac }}
          </td>
          <td>
            {{
              $options.filters.formatContactNameSimple(
                item,
                "or_",
                false,
                "_liv"
              )
            }}
          </td>
          <td>{{ item.or_or | formatChiffreSimple }}</td>
          <td>{{ item.or_argent | formatChiffreSimple }}</td>
          <td>{{ item.or_excellence | formatChiffreSimple }}</td>
          <td>{{ item.or_num }} | {{ item.or_date | formatDate }}</td>
          <!-- <td>{{ item.in_num }}</td> -->
          <td class="text-end">
            {{ item.or_price_ht | formatPriceDivisePar100 }} +
            {{ item.or_fraisport | formatPriceDivisePar100 }}
          </td>
          <td v-html="$options.filters.formatYesNoColored(item.or_payed)"></td>
          <td>
            {{
              item.or_state === "SENDED"
                ? $options.filters.formatDate(item.or_datesended)
                : "Non"
            }}
            ({{ item.or_state | formatOrderState }})
          </td>
          <td class="d-flex">
            <div
              @click.stop="downloadInvoice(item)"
              v-tooltip
              data-bs-placement="left"
              title="Télécharger la facture"
            >
              <icon color="#3fb1dc" name="print"></icon>
            </div>
            <div
              @click.stop="sendEmail(item)"
              class="ms-2"
              v-tooltip
              data-bs-placement="left"
              title="Envoyer l'email de creation, reception ou envoi selon l'état de la commande"
            >
              <icon color="#3fb1dc" name="envelope"></icon>
            </div>
            <div
              v-if="!item.or_payed"
              @click.stop="confirmDeleteOrder(item)"
              class="ms-2"
              v-tooltip
              data-bs-placement="left"
              title="Effacer la commande"
            >
              <icon color="#950605" name="trash-alt"></icon>
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>

    <order-new-1
      v-model="orderWinEdit"
      :or_id="orderCreating.or_id"
      @WinEditActions="WinNew1Actions"
    >
    </order-new-1>
    <order-new-2
      v-model="orderWinEdit2"
      :or_id="orderCreating.or_id"
      @returnStep1="returnStep1"
      @WinEditActions="WinNew2Actions"
    >
    </order-new-2>
    <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer cette commande ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteOrder()"
    ></m-confirm-dialog>
  </section>
</template>

<script>
export default {
  name: "Orders",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      orders: [],
      orders_total: 0,
      orders_limit: 1000,
      orders_skip: 0,
      orderSelected: { or_id: -1 },
      orderCreating: {},
      // filters
      filterText: "",
      filterSended: false,
      filterReceived: true,
      filterPayed: true,
      filterCreating: true,
      filterValidated: true,
      delayFilter: null,
      sortOrder: "desc",
      sortFields: ["or_date"],
      // actions
      orderWinEdit: false,
      orderWinEdit2: false,
      orderToDelete: {},
      confirmdelete: false
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canMacaronsAccess")) {
      this.$router.push("/");
    }
    this.loadOrders(0);
    if (this.$route.name == "OrderStep1")
      this.returnStep1({ or_id: this.$route.params.id * 1 });
    if (this.$route.name == "OrderStep2")
      this.orderDetails({ or_id: this.$route.params.id * 1 });
  },
  watch: {
    $route(to, from) {},
    year: async function(val) {
      await this.$store.dispatch("set_year", val);
      this.loadOrders();
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
    }
  },
  computed: {},
  methods: {
    downloadInvoice(item) {
      // console.log("item", item);
      window.open(
        `${this.$config.server_url}/backoffice/1.0/orders/print/${item.or_id}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadOrders();
    },
    orderDetails(item) {
      this.orderCreating = item;
      //this.$router.push("/orders/" + item.or_id + "/details");
      this.$router
        .push("/orders/" + this.orderCreating.or_id + "/step2")
        .catch(err => {});
      this.orderWinEdit2 = true;
    },
    loadDelayOrders() {
      if (this.delayFilter) {
        clearTimeout(this.delayFilter);
        this.delayFilter = null;
      }
      this.delayFilter = setTimeout(() => {
        this.loadOrders();
      }, 300);
    },
    async loadOrders(skip = 0) {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        filterSended: this.filterSended,
        filterReceived: this.filterReceived,
        filterPayed: this.filterPayed,
        filterCreating: this.filterCreating,
        filterValidated: this.filterValidated,
        skip: skip,
        limit: this.orders_limit,
        sort: sort,
        or_year: this.$store.state.year
      };
      this.cancelAxiosloadOrders && this.cancelAxiosloadOrders();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/orders",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosloadOrders = c;
          })
        }
      );
      if (!response || !response.data) return;
      let orders = response.data.data;
      this.orders_total = this.orders.length;
      this.orders_skip = skip;
      this.orders = orders;
    },
    newOrders() {
      this.orderCreating = { or_id: -1 };
      this.$router
        .push("/orders/" + this.orderCreating.or_id + "/step1")
        .catch(err => {});
      this.orderWinEdit = true;
    },
    WinNew1Actions(data) {
      // console.log("data", data);
      if (data.action === "canceled") {
        this.$router.push("/orders").catch(err => {});
        this.orderCreating = {};
      }
      if (data.action === "saved") {
        this.orderCreating = data.data;
        this.orderWinEdit2 = true;
        this.$router
          .push("/orders/" + this.orderCreating.or_id + "/step2")
          .catch(err => {});
      }
    },
    returnStep1(row_or) {
      this.orderCreating = row_or;
      this.orderWinEdit = true;
      this.$router
        .push("/orders/" + this.orderCreating.or_id + "/step1")
        .catch(err => {});
    },
    WinNew2Actions(data) {
      this.$router.push("/orders").catch(err => {});
      this.orderCreating = {};
      // console.log("data.action", data.action);
      if (data.action === "saved" || data.action === "deleted")
        this.loadOrders();
      this.$store.dispatch("loadStats");
    },
    async sendEmail(item) {
      let response = await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/orders/" +
          item.or_id +
          "/sendemail"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: response.data.data.pa_society,
          text: response.data.data.email + " bien envoyé",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    },
    confirmDeleteOrder(item) {
      this.orderToDelete = item;
      this.confirmdelete = true;
    },
    async deleteOrder() {
      await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/orders/" +
          this.orderToDelete.or_id
      );
      this.orderToDelete = {};
      this.confirmdelete = false;
      this.loadOrders();
      this.$store.dispatch("loadStats");
    }
  }
};
</script>

<style lang="scss" scoped></style>
