<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <h3 class="mb-0 d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="mail-bulk"></icon>
            </div>
            <div>
              <div>
                {{
                  ml_id > 0
                    ? "Modification d'une mailingliste"
                    : "Création d'une mailingliste"
                }}
              </div>
              <div class="fs-6 fw-light">
                Administrateur
              </div>
            </div>
          </h3>
        </div>
        <div class="modal-body">
          <div class="frame">
            <div class="row mb-2">
              <div class="col-md-6">
                <h4>Données</h4>
                <!--  <m-form-combobox
                  v-model="row_ml.user"
                  label="Envoyé par"
                  :store-url="$config.server_url + '/backoffice/1.0/users'"
                  item-value="us_id"
                  :item-text="$options.filters.formatContactNameSimpleEmail"
                  :item-text-params="'us_'"
                ></m-form-combobox> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <m-form-text
                  label="Titre *"
                  class="mb-2"
                  :name="$Utils.randomstring('objet')"
                  v-model="row_ml.ml_object"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
                <m-form-text-markdown
                  label="Texte de l'email *"
                  v-model="row_ml.ml_text"
                  :rows="12"
                ></m-form-text-markdown>
                <help-variables></help-variables>
              </div>
            </div>
            <div class="row">
              <h5 class="bis d-flex align-items-center mt-3">Tester</h5>
              <div class="col-md-6">
                <div class="d-flex align-items-end">
                  <m-form-text
                    label="Email pour le test *"
                    style="width:300px"
                    :name="$Utils.randomstring('test')"
                    v-model="row_ml.ml_email_test"
                  ></m-form-text>
                  <button
                    class="btn btn-primary btn-sm ms-2"
                    @click="sentToTest"
                  >
                    Envoyer le test
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <m-form-combobox
                  v-model="candidatTest"
                  style="max-width:870px"
                  label="Candidat de test"
                  :store-url="
                    $config.server_url + '/backoffice/1.0/mailinglists/search'
                  "
                  item-value="pa_id"
                  :item-text="$options.filters.formatContactNameSimpleEmail"
                  :item-text-params="'pa_'"
                  :store-params="row_ml.ml_search"
                ></m-form-combobox>
              </div>
            </div>
          </div>
          <div class="frame">
            <h4>Votre recherche : {{ contacts.length }} contacts</h4>
            <div class="row">
              <div class="col-md-6">
                <m-form-select
                  label="Type de participation"
                  :name="$Utils.randomstring('type')"
                  :items="$store.state.items_typeparticipation"
                  v-model="row_ml.ml_search.type"
                  @input="searchContacts()"
                ></m-form-select>
              </div>
              <div class="col-md-3">
                <m-form-select
                  label="État de la participation"
                  :name="$Utils.randomstring('items_stateparticipation')"
                  :items="$store.state.items_stateparticipation"
                  v-model="row_ml.ml_search.state"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
              <div class="col-md-2">
                <m-form-select
                  label="Année"
                  :name="$Utils.randomstring('items_years')"
                  :items="$store.state.items_years"
                  v-model="row_ml.ml_search.year"
                  @input="searchContacts()"
                ></m-form-select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3">
                <m-form-select
                  label="Dénomination *"
                  class="mb-2"
                  :name="$Utils.randomstring('de_id')"
                  :items="denominations"
                  v-model="row_ml.ml_search.denomination"
                  :rules="[$Validation.mandatory]"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
              <div class="col-md-2">
                <m-form-select
                  label="Facture payées"
                  :name="$Utils.randomstring('items_invoice_payed')"
                  :items="$store.state.items_invoice_payed"
                  v-model="row_ml.ml_search.payed"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
              <div class="col-md-2">
                <m-form-select
                  label="Type de paiement"
                  :name="$Utils.randomstring('items_paiement')"
                  :items="$store.state.items_paiement"
                  v-model="row_ml.ml_search.paiement"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
              <div class="col-md-3">
                <m-form-select
                  label="Type de Facture"
                  :name="$Utils.randomstring('items_invoice_type')"
                  :items="$store.state.items_invoice_type"
                  v-model="row_ml.ml_search.typeFacture"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <m-form-select
                  label="Vins médaillés"
                  :name="$Utils.randomstring('items_medailles')"
                  :items="$store.state.items_medailles_search"
                  v-model="row_ml.ml_search.medaille"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
              <div class="col-md-3">
                <m-form-text
                  label="Nombre de vins minimum"
                  type="number"
                  v-model="row_ml.ml_search.winemin"
                  :name="$Utils.randomstring('winemin')"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-text>
              </div>
              <div class="col-md-3">
                <m-form-select
                  label="Vins stop"
                  :name="$Utils.randomstring('items_stop')"
                  :items="$store.state.items_stop"
                  v-model="row_ml.ml_search.stop"
                  @input="searchContacts()"
                  :disabled="disabledFielsSearch"
                ></m-form-select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <button @click="showContacts" class="btn btn-primary btn-sm">
                  Voir les {{ contacts.length }} contacts
                </button>
                <button
                  @click="addContacts"
                  class="btn btn-primary btn-sm ms-2"
                >
                  Ajouter les {{ contacts.length }} contacts
                </button>
                <button
                  @click="cancelCaddy"
                  class="btn btn-primary btn-sm ms-2"
                >
                  Vider le caddy
                </button>
                <button @click="resetSearch" class="btn btn-light btn-sm ms-2">
                  Réinitialiser la recherche
                </button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <h4>Votre caddy : {{ caddy.length }} contacts</h4>
                <m-list-simple
                  tableClass="table-striped"
                  ref="winelisttoavoir"
                  :dynamic="true"
                  :items="caddy"
                  item-value="pa_id"
                  :item-height="30"
                  :skip="0"
                  :total="caddy.length"
                >
                  <template v-slot:ths="{}">
                    <th><span>Société</span></th>
                    <th><span>Contact</span></th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.participation.pa_society }}</td>
                    <td>
                      {{
                        $options.filters.formatContactNameSimple(
                          item.participation,
                          "pa_"
                        )
                      }}
                      <span v-if="item.participation.login">
                        ({{ item.participation.login.lo_login }})</span
                      >
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <button
            type="button"
            class="btn btn-outline-danger"
            @click="deleteWin"
          >
            Supprimer
          </button>
          <div class="ms-auto"></div>
          <div class="d-flex align-items-center" v-if="$Utils.isAdmin()">
            <div v-if="sendMailinglist" class="me-2">
              Envoyer définitivement
            </div>
            <div v-else class="me-2">Ne pas envoyer tout de suite</div>
            <label class="switch">
              <input v-model="sendMailinglist" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>

          <button type="button" class="btn btn-secondary" @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmDeleteDialog"
      text="Souhaitez-vous vraiment supprimer cette mailingliste ?"
      title="Confirmation"
      @canceled="confirmDeleteDialog = false"
      @confirmed="deleteWinConfirmed()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="confirmSendEmail"
      :text="confirmSendEmailTxt"
      title="Confirmation"
      @canceled="confirmSendEmail = false"
      @confirmed="saveWin()"
    ></m-confirm-dialog>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
      color="red"
    ></m-message-dialog>
    <m-message-dialog
      v-model="showContactsSearch"
      :title="`résultat de votre recherche : ${contacts.length} contacts`"
      :text="contactsSearchTxt"
      :width="800"
      color="blue"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "MailinglistWinEdit",
  components: {},
  props: {
    ml_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      row_ml: { ml_search: {}, participation_test: { login: {} } },
      emailToTest: "",
      mailToCopy: {},
      mailsCopy: [],
      confirmDeleteDialog: false,
      dialogErr: false,
      dialogErrTxt: "",
      /*       showHelpVariables: false,
      openMustache: "{{",
      closeMustache: "}}", */
      contacts: [],
      caddy: [],
      denominations: [],
      search: {
        type: "",
        state: "",
        stop: "",
        payed: "",
        typeFacture: "",
        paiement: "",
        denomination: "",
        winemin: 0,
        medaille: "",
        year: ""
      },
      showContactsSearch: false,
      contactsSearchTxt: "",
      disabledFielsSearch: false,
      sendMailinglist: false,
      confirmSendEmail: false,
      confirmSendEmailTxt: "",
      candidatTest: {}
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.contacts = [];
        this.showContactsSearch = false;
        this.contactsSearchTxt = "";
        this.candidatTest = {};
        this.loadMailinglist();
        this.loadDenominations();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadDenominations() {
      let params = {
        de_year: this.$store.state.year,
        de_save: 1,
        sort: "de_name ASC"
      };
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/denominations/",
        { params }
      );
      let rows_de = response.data.data;

      for (let ide = 0; ide < rows_de.length; ide++) {
        const row_de = rows_de[ide];
        row_de.text = row_de.de_name;
        row_de.value = row_de.de_id;
      }
      /* rows_de.unshift({
        value: "tous",
        text: "Toutes"
      }); */
      this.denominations = rows_de;
    },
    async loadMailinglist() {
      // this.cancelCaddy();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/mailinglists/" + this.ml_id
      );
      this.row_ml = response.data.data;
      if (!this.row_ml.ml_search) this.row_ml.ml_search = this.search;
      // console.log("this.row_ml.ml_search", this.row_ml.ml_search);
      // await this.searchContacts();
      // this.addContacts();
      // emails copy
      //this.mailsCopy = this.row_ml.emails_copy;
      this.searchContacts();
      this.candidatTest = this.row_ml.participation_test;
      if (this.ml_id > 0) this.loadCaddy();
    },
    async loadCaddy() {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/backoffice/1.0/mailinglists/" +
          this.row_ml.ml_id +
          "/caddy"
      );
      this.caddy = response.data.data;
      // console.log("this.caddy", this.caddy);
    },
    /*     addCopy() {
      if (this.mailsCopy.length) {
        for (let i = 0; i < this.mailsCopy.length; i++) {
          const user = this.mailsCopy[i];
          if (user.us_id === this.mailToCopy.us_id) return;
        }
      }
      this.mailsCopy.push(this.mailToCopy);
      this.mailToCopy = {};
    }, */
    async searchContacts() {
      //console.log("this.search", this.search);
      if (this.row_ml.ml_search.type === "pa_jure")
        this.disabledFielsSearch = true;
      else this.disabledFielsSearch = false;
      let params = this.row_ml.ml_search;
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/mailinglists/search",
        { params }
      );
      this.contacts = response.data.data;
    },
    showContacts() {
      this.contactsSearchTxt = "";
      this.showContactsSearch = true;
      for (let i = 0; i < this.contacts.length; i++) {
        const contact = this.contacts[i];
        this.contactsSearchTxt += `<div> ${contact.pa_society} -
                  ${this.$options.filters.formatContactNameSimple(
                    contact,
                    "pa_"
                  )}
                  (${contact.lo_login}) </div>`;
      }
    },
    async addContacts() {
      if (!this.row_ml.ml_id) {
        let ok = await this.tryToSaveWin(false);
        if (!ok) return;
      }
      // this.caddy = this.caddy.concat(this.contacts);
      let contacts = this.contacts.map(row_pa => {
        return row_pa.pa_id;
      });
      await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/mailinglists/" +
          this.row_ml.ml_id +
          "/addcontacts",
        { contacts }
      );

      this.contacts = [];
      this.resetSearch();
      this.loadCaddy();
    },
    async cancelCaddy() {
      // this.caddy = [];
      await this.$axios.post(
        this.$config.server_url +
          "/backoffice/1.0/mailinglists/" +
          this.row_ml.ml_id +
          "/removecontacts",
        {}
      );
      this.loadCaddy();
    },
    resetSearch() {
      this.search = {
        type: "",
        state: "",
        stop: "",
        payed: "",
        typeFacture: "",
        paiement: "",
        denomination: "",
        winemin: 0,
        medaille: "",
        year: ""
      };
      this.row_ml.ml_search = this.search;
    },
    async sentToTest() {
      this.sendMailinglist = false;
      await this.tryToSaveWin(false);
      if (this.dialogErr) return;
      if (!this.candidatTest) {
        this.dialogErrTxt = "Merci de définir un candidat de test";
        this.dialogErr = true;
      }
      let data = {
        ml_id: this.row_ml.ml_id,
        to: this.row_ml.ml_email_test,
        candidatTest: this.candidatTest
      };
      let response = await this.$axios.post(
        this.$config.server_url + "/backoffice/1.0/mailinglists/test",
        data
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Email de test",
          text: "Email de test bien envoyé",
          color: "#2aaa2a"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Email de test",
          text: "Erreur lors de l'envoi du mail de test",
          color: "red"
        });
      }
    },
    async tryToSaveWin(closeWin = true) {
      let err = [];
      let fieldRequired = [
        { field: "ml_object", text: "Objet" },
        { field: "ml_text", text: "Text" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_ml[field.field]) err.push(field);
      }
      //vérifier le champs email de test
      if (!this.row_ml.ml_email_test) {
        err.push({ text: "Email de test" });
      }
      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return false;
      }
      if (this.sendMailinglist) {
        this.confirmSendEmail = true;
        this.confirmSendEmailTxt =
          "vous allez envoyer ce mail aux contacts sélectionnés";
      } else {
        await this.saveWin(closeWin);
      }
      return true;
    },
    async saveWin(closeWin) {
      this.confirmSendEmail = false;
      this.confirmSendEmailTxt = "";
      // reformater les emails en copy pas utilisé pour le moment
      /* this.row_ml.ml_copy = "";
      for (let i = 0; i < this.mailsCopy.length; i++) {
        const user = this.mailsCopy[i];
        this.row_ml.ml_copy += user.us_email;
        if (i < this.mailsCopy.length - 1) this.row_ml.ml_copy += ",";
      } */

      let contacts = [];
      for (let i = 0; i < this.contacts.length; i++) {
        const pa = this.contacts[i];
        contacts.push(pa.pa_id);
      }
      this.row_ml.contacts = contacts;
      this.row_ml.ml_year = this.$store.state.year;
      this.row_ml.sendMailinglist = this.sendMailinglist;
      if (this.candidatTest) {
        this.row_ml.pa_id_test = this.candidatTest.pa_id;
        this.row_ml.participation_test = this.candidatTest;
      }
      // console.log("this.row_ml", this.row_ml);
      let response;
      if (this.row_ml.ml_id) {
        response = await this.$axios.put(
          this.$config.server_url +
            "/backoffice/1.0/mailinglists/" +
            this.row_ml.ml_id,
          this.row_ml
        );
      } else {
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/mailinglists",
          this.row_ml
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.row_ml = response.data.data;
      // console.log("this.row_ml", this.row_ml);
      if (closeWin) {
        this.$emit("input", false);
        this.$emit("saved", this.row_ml.ml_id);
        this.$store.dispatch("showAlertDialog", false);
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("canceled");
      this.$store.dispatch("showAlertDialog", false);
    },
    deleteWin() {
      this.confirmDeleteDialog = true;
    },
    async deleteWinConfirmed() {
      let ml_id = this.row_ml.ml_id;
      let response = await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/mailinglists/" + ml_id
      );
      if (response.data.err.key === "user_no_rights") {
        this.confirmDeleteDialog = false;
        this.$store.dispatch("showAlertDialog", {
          text: "Vous ne pouvez pas supprimer cette mailinglist",
          type: "danger",
          container: "emailWinEdit"
        });
      } else {
        this.confirmDeleteDialog = false;
        this.$emit("input", false);
        this.$emit("deleted");
        this.$store.dispatch("showAlertDialog", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.active {
  //min-width: 150px;
  input {
    width: auto;
  }
}
</style>
